import {
  createReducer
} from 'redux-act';
import {
  requestDashboardData,
  responseDashboardData,
  requestGetSessions,
  responseGetSessions,
  requestGetTopScreenViews,
  responseGetTopScreenViews,
  requestGetUserVsReturningUser, 
  responsGetUserVsReturningUser
} from './action';


const defaultState = {
  requestProcess: false,
};

const reducer = createReducer({

  [requestDashboardData]: (state) => {
    return {
      requestProcess: true,
      data: [],
    };
  },

  [responseDashboardData]: (state, params) => {
    return {
      requestProcess: false,
      data: params,
    };
  },


  [requestGetSessions]: (state) => {
    return {
      requestProcess: true,
    };
  },

  [responseGetSessions]: (state, params) => {
    return {
      requestProcess: false,
      sessonData: params,
    };
  },


  [requestGetTopScreenViews]: (state) => {
    return {
      requestProcess: true,
    };
  },

  [responseGetTopScreenViews]: (state, params) => {
    return {
      requestProcess: false,
      topScreenViews: params,
    };
  },


  [requestGetUserVsReturningUser]: (state) => {
    return {
      requestProcess: true,
    };
  },

  [responsGetUserVsReturningUser]: (state, params) => {
    return {
      requestProcess: false,
      userVsReturningUser: params,
    };
  },


}, defaultState);



export default reducer