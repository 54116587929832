import {createAction} from 'redux-act';
import store from './../store';
import httpClient from '../services/http';
import session from '../utils/session';

export const requestLoaderVisibility = createAction();
export const requestSuccessMessage = createAction();
export const requestErrorMessage = createAction();

export const requestRefreshAppData = createAction();


export function refreshAppData(){
  store.dispatch(requestRefreshAppData(true));
  setTimeout(()=>{
    store.dispatch(requestRefreshAppData(false));
  }, 500)
}

export function SuccessToast(message){
  store.dispatch(requestSuccessMessage(message));
  setTimeout(()=>{
    store.dispatch(requestSuccessMessage(''));
  }, 3000)
}

export function ErrorToast(message){
  store.dispatch(requestErrorMessage(message));
  setTimeout(()=>{
    store.dispatch(requestErrorMessage(''));
  }, 3000)
}

export function Loader(isVisible, processBarPercent){
  let param = {
      isVisible: isVisible,
      processBarPercent: processBarPercent
  }
  store.dispatch(requestLoaderVisibility(param))
}


export function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export function setHttpClientConfig(){
  return new Promise(function(resolve, reject){
      let sessionData = session.getStorageData()
      let headers = {};
      if(sessionData && sessionData['token']){
          headers['Authorization'] = sessionData ? 'Bearer '+sessionData['token'] : '';
      }
      headers['X-ADMIN-KEY'] = process.env.REACT_APP_API_KEY;
      headers['X-API-KEY'] = process.env.REACT_APP_API_KEY;
      headers['X-Content-Type-Options'] = "nosniff";
      headers['Accept'] = "application/json";
      headers['Content-Type'] = 'application/json;charset=utf-8';
      httpClient.setConfig({
          API_URL: process.env.REACT_APP_API_URL,
          HEADERS: headers,
          REACT_APP_CURRENT_API_VERSION: process.env.REACT_APP_CURRENT_API_VERSION
      });
      resolve(true);
  });
}

export function logout(){
  session.deleteSessionData();
}

export function cleanString(stringVal){
    return stringVal
          .replace(/&amp;/g, '')
          .replace(/"/g, '&quot;')
          .replace(/#/g, '&num;')
          .replace(/'/g, '&apos;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;');
}


export const cardBg = ['bg-info', 'bg-success', 'bg-warning', 'bg-danger', 'bg-primary']

export const iconByName = { 
  'Users':'icon-user',
 'New Users':'icon-people',
 'Sessions':'icon-clock',
 'Screen View':'cui-monitor'
}


export function createReferralCode() {
   return (Math.random().toString(36).substring(2, 6)).toUpperCase();
}

export function createCouponCode() {
  return (Math.random().toString(36).substring(2, 8)).toUpperCase()
}


export function msToTime (milliseconds) {
        
    let hours = milliseconds / (1000*60*60);
    let h = Math.floor(hours);

    //Get remainder from hours and convert to minutes
    let minutes = (hours - h) * 60;
    let m = Math.floor(minutes);

    //Get remainder from minutes and convert to seconds
    let seconds = (minutes - m) * 60;
    let s = Math.floor(seconds);

    return h + 'hrs ' + m + 'min ' + s +'sec';
      
}

export function convertToHours( milliseconds ) {
    var day, hour, minute, seconds;
    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    hour = Math.floor(minute / 60);
    return Math.ceil(hour / 10) * 10;
}


export function formatText( text ) {
  if (typeof text !== 'string') return ''
  text = text.replace(/_/g, ' ');
  text = text.toLowerCase();
  text = text.charAt(0).toUpperCase() + text.slice(1);
  return text
}

