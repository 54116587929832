import React, { Component } from 'react';
import { Button, Col,Row, Popover, PopoverBody } from 'reactstrap';
import Session from '../../utils/session';
import {refreshAppData} from '../../utils/common';
import moment from 'moment';
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css' 


class AppsView extends Component {

	constructor(props) {
	    super(props);
	    this.userData = Session.getSession();
	    this.displayDateOnScreen = ["/app-activity", "/audience-overview", "/screen-analytics", "/install-vs-user"];
	    let appsData = Session.getStorageData('apps');
	    let dates = Session.getStorageData('dates')
	    if(!dates || !dates.startDate){
	    	let storedDates = {startDate: moment().add(-6, 'day').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')}
	    	Session.setSessionData(storedDates, 'dates');
	    	dates = storedDates;
	    }

	    this.state = {
	      selectedApp: appsData,
	      isPickerOpen: false,
	      startDate: dates && dates.startDate ? moment(dates.startDate) : moment().add('-7', 'days'),
	      endDate: dates && dates.endDate ? moment(dates.endDate) : moment()
	    };

	    this.apps = []
	}


	componentWillMount(){
		this.apps = (this.userData && this.userData.apps && this.userData.apps.length) ? this.userData.apps : [];
	}


	onSelectApp(elementObj){
		let {selectedApp} = this.state;
		let index = selectedApp.indexOf(elementObj.key);
		if(index === -1){
			selectedApp.push(elementObj.key);
		}else{
			selectedApp.splice(index, 1);
		}
		Session.setSessionData(selectedApp, 'apps');
		this.setState({selectedApp: selectedApp}, ()=>{
			refreshAppData();			
		});
	}

	handleDatePicker(start, end){
		let storedDates = {startDate: moment(start).format('YYYY-MM-DD'), endDate: moment(end).format('YYYY-MM-DD')}
		Session.setSessionData(storedDates, 'dates');
		this.setState({startDate: start, endDate: end, isPickerOpen: false}, ()=>{
			refreshAppData();
		});
	}

	toggleDateRangePicker(){
		let {isPickerOpen} = this.state;
		this.setState({isPickerOpen: !isPickerOpen});
	}

  render() {
  	let {selectedApp, startDate, endDate, isPickerOpen} = this.state;
  	let displayDatePicker = this.displayDateOnScreen.indexOf(this.props.location.pathname) !== -1;
    return (
		<div className="container-fluid pb-0 pt-4">
			<div className="animated fadeIn">
          		<Row>          			
          			{
          				this.apps.map((element, key)=>{
          					let index = selectedApp.indexOf(element.key);
          					return (
          						<Col key={key} xs="6" sm="6" lg="3" >
          							<div style={{backgroundColor: index !== -1 ? /*element.color*/ "#668cff" : '#f6f6f8', color: index !== -1 ? '#FFFFFF' : '#abaeb0', pointerEvents : (this.apps.map.length > 1) ? 'all' : 'none' }}
          							className={"text-center add-app-box p-2"} 
          							onClick={()=>{this.	onSelectApp(element)}}>
			          					<div title={element.name}><i className={element.iconClass}></i> {element.name} ({element.identifier})</div>
			          				</div>
			          			</Col>
          					)
          				})
          			}
          			{displayDatePicker && 
						<Col xs="6" sm="6" lg="9">
							<div className="float-right pr-3">
	          					<label className="date-range-output" id="dateRangePicker">
		          					<i className="icon-calendar"></i>
		          					<span className="desktop-only">{moment(startDate).format('MMMM D, YYYY')} - {moment(endDate).format('MMMM D, YYYY')}</span>	          					
		          					<Button id="dateRangePicker" type="button" onClick={()=>{this.toggleDateRangePicker()}}>
						          		<i className="icon-arrow-down"></i>
						        	</Button>
	          					</label>
	          				</div>
	          				<Popover placement="bottom" isOpen={isPickerOpen} target="dateRangePicker" toggle={this.toggle}>
								<PopoverBody>
								<DateRangePicker
									value={moment.range(startDate, endDate)}    
									maximumDate={new Date()}      						
									onSelect={({start, end})=>{this.handleDatePicker(start, end)}}
									numberOfCalendars={2}
									selectionType="range" />
								</PopoverBody>
					        </Popover>
	          			</Col>
          			}	
          		</Row>
          		
          		
          	</div>
	    </div>
    );
  }
}



export default AppsView;
