import {createAction} from 'redux-act';
import httpClient from '../../../services/http';

export const requestPillarEmotionFrequencyData = createAction();
export const responsePillarEmotionFrequencyData = createAction();




export function getPillarEmotionFrequency(params){
    return (dispatch) => {
        dispatch(requestPillarEmotionFrequencyData([]));
        httpClient.call('ANALYTICS', '/get_pillar_emotion_frequency', params, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responsePillarEmotionFrequencyData(response));
        }, function(error){
            dispatch(responsePillarEmotionFrequencyData(error));
        });
    }
  }

