import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
//import registerServiceWorker from './registerServiceWorker';
import store from './store';
import AppRoute from './AppRoute';

if(process.env.REACT_APP_MODE === 'prod'){
	console.log = () => {};
}

ReactDOM.render(
  	<Provider store={store}>
    	<AppRoute />
  	</Provider>,
  	document.getElementById('root')
);
//registerServiceWorker();
