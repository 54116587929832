import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import DefaultLayout from './containers/DefaultLayout'
import Message from './components/Message'
import Loader from './components/Loader';
import { setHttpClientConfig } from './utils/common';
import './App.scss';
import "react-datepicker/dist/react-datepicker.css";
import httpClient from './services/http';

class AppRoute extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isError : false
    }
  }


  componentWillMount(){
    setHttpClientConfig().catch(() => {
      this.setState({isError : true})
    })
  }


  render() {
    let {isError} = this.state;
    return (
      <section>
      <Message />
      <Loader />
        {!isError ? <Router>
            <Route path="/" name="Dashboard" render={props => <DefaultLayout {...props}/>} />
        </Router>: null}
      </section>
    );
  }
}

export default AppRoute;
