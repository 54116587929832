import {createAction} from 'redux-act';
import httpClient from '../../services/http';

export const requestUserData = createAction();
export const responseUserData = createAction();

export const requestUserByDate = createAction();
export const responseUserByDate = createAction();

export const requestUserHistory = createAction();
export const responseUserHistory = createAction();

export const requestScreenHistory = createAction();
export const responseScreenHistory = createAction();




export function getAudienceData(params){
    return (dispatch) => {
        dispatch(requestUserData([]));
        httpClient.call('ANALYTICS', '/get_audience_data', params, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responseUserData(response));
        }, function(error){
            dispatch(responseUserData(error));
        });
    }
}

export function getUsersByDate(params){
	return (dispatch) => {
	    dispatch(requestUserByDate([]));
	    httpClient.call('ANALYTICS', '/get_users_by_date', params, {method: 'GET', headers: {}}).then(function(response){
	        dispatch(responseUserByDate(response));
	    }, function(error){
	        dispatch(responseUserByDate(error));
	    });
	}
}

export function getUsersHistory(params){
	return (dispatch) => {
	    dispatch(requestUserHistory([]));
	    httpClient.call('ANALYTICS', '/get_user_history', params, {method: 'GET', headers: {}}).then(function(response){
	        dispatch(responseUserHistory(response));
	    }, function(error){
	        dispatch(responseUserHistory(error));
	    });
	}
}

export function getScreenHistory(params){
	return (dispatch) => {
	    dispatch(requestScreenHistory([]));
	    httpClient.call('ANALYTICS','/get_screen_history', params, {method: 'GET', headers: {}}).then(function(response){
	        dispatch(responseScreenHistory(response));
	    }, function(error){
	        dispatch(responseScreenHistory(error));
	    });
	}
}


