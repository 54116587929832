import {createAction} from 'redux-act';
import httpClient from '../../services/http';

export const requestDashboardData = createAction();
export const responseDashboardData = createAction();




export function getAliveUsers(offset, rowsPerPage){
  return (dispatch) => {
      dispatch(requestDashboardData([]));
      httpClient.call('ANALYTICS', '/get_current_users', {offset: offset, rowsPerPage:rowsPerPage}, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseDashboardData(response));
      }, function(error){
          dispatch(responseDashboardData(error));
      });
  }
}
