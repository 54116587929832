import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';
import { routerReducer } from 'react-router-redux';

import {requestLoaderVisibility, requestSuccessMessage, requestErrorMessage, requestRefreshAppData} from './utils/common';
import login from './views/Pages/Login/reducer';
import dashboard from './views/Dashboard/reducer';
import aliveUsers from './views/AliveUsers/reducer';
import appSummaryGraph from './views/Graphs/AppSummary/reducer';
import signupGraph from './views/Graphs/Signup/reducer';
import screenAnalyticsReport from './views/Graphs/ScreenAnalyticsReport/reducer';
import screenContentReport from './views/Graphs/ScreenContentReport/reducer';
import pillarEmotionFrequencyPie from './views/Graphs/PillarEmotionFrequencyPie/reducer';
import realTimeOverview from './views/RealTimeOverview/reducer';
import audienceOverview from './views/AudienceOverview/reducer';
import audienceOverviewGraph from './views/Graphs/AudienceOverviewGraph/reducer';
import spendTimePieGraph from './views/Graphs/SpendTimePie/reducer';
import weeklySessionDuration from './views/Graphs/WeeklySessionDuration/reducer';
import installUninstallVsUser from './views/InstallUninstallVsUser/reducer';
import sdkDownload from './views/SdkDownload/reducer';
import screenview from './views/ScreenView/reducer';
import crashes from './views/Crashes/reducer';
import screenEvent from './views/ScreenEvent/reducer';


/***************************Start Reducer for refresh app data *************/
const defaultRefreshState = false

const refreshData = createReducer({
  [requestRefreshAppData]: (state, params) => {
      return params;
  }
}, defaultRefreshState);
/***************************End Reducer for refresh app data *************/


/***************************Start Reducer for loader *************/
const defaultLoaderState = {
	visiblity: false,
	processBarPercent: 0
};

const loader = createReducer({
  [requestLoaderVisibility]: (state, params) => {
			return {
				visiblity: params.isVisible,
				processBarPercent: params.processBarPercent
			};
	}
}, defaultLoaderState);
/***************************End Reducer for loader *************/

/***************************Start Reducer for Message *************/
const defaultMessageState = {
	message: '',
  type: ''
};

const messageObj = createReducer({
  [requestSuccessMessage]: (state, params) => {
      if(params){
        return {
          message: params,
          type: 'success'
        };
      }
			return {
        message: '',
        type: ''
			};
	},
  [requestErrorMessage]: (state, params) => {
      if(params){
        return {
          message: params,
          type: 'error'
        };
      }
			return {
        message: '',
        type: ''
			};
	}
}, defaultMessageState);
/***************************End Reducer for Message *************/

const reducers = combineReducers({
		routing: routerReducer,
    messageObj,
    loader,
    refreshData,
    login,
    dashboard,
    aliveUsers,
    appSummaryGraph,
    signupGraph,
    screenAnalyticsReport,
    screenContentReport,
    pillarEmotionFrequencyPie,
    realTimeOverview,
    audienceOverview,
    audienceOverviewGraph,
    spendTimePieGraph,
    weeklySessionDuration,
    installUninstallVsUser,
    sdkDownload,
    screenview,
    crashes,
    screenEvent
    
});

export default reducers;
