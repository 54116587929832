

export default {
  items: [    
    {
      name: 'Dashboard',
      icon: 'icon-speedometer',
      url: '/dashboard',      
    },


    {
      name: 'Real-time',
      url: '/realtime-overview',
      icon: 'icon-clock',
    },
    {
      title: false,
      name: 'Audience',      
      icon: 'icon-people',
      children: [
      // {
      //   name: 'Users Insights',
      //   url: '/user-list',
      //   icon: 'icon-user',
      // },
      {
        name: 'Users Overview',
        url: '/audience-overview',
        icon: 'icon-user',
      }]
    },
    {
      name: 'Screen Analytics',
      url: '/screen-analytics',
      icon: 'cui-monitor',
      children: [{
        name: 'Screen Events',
        icon: 'icon-screen-desktop',
        url: '/screen-event',
      },
      {
        name: 'Screen Views',
        url: '/screen-view',
        icon: 'icon-magnifier-add',
      }]
    },
    {
      name: 'Usage',
      icon: 'icon-grid',
      children: [{
        name: 'App Activity',
        url: '/app-activity',
        icon: 'icon-graph',
      },
      {
        name: 'Active Device',
        url: '/active-device',
        icon: 'icon-login',
      },
      {
        name: 'Active User',
        url: '/active-user',
        icon: 'icon-user',
      }]
    }, 
    /* {
      name: 'Install Vs User',
      url: '/install-vs-user',
      icon: 'fa fa-user-circle-o',
    }, */
    {
      name: 'Crash Logs',
      icon: 'icon-ban',
      // url: '/crashes',
      children: [{
        name: 'IOS',
        url: '/crashes-ios',
        icon: 'icon-graph',
      },{
        name: 'ANDROID',
        url: '/crashes-android',
        icon: 'icon-graph',
      }]
    },   
    {
      name: 'Download SDK',
      icon: 'icon-cloud-download',
      url: '/sdk-download',      
    }
  ],
};
