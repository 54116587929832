import { createAction } from 'redux-act';
import httpClient from '../../services/http';

export const requestEventData = createAction();
export const responseEventData = createAction();


export function getEventData(params) {
  return (dispatch) => {
    dispatch(requestEventData([]));
    httpClient.call('ANALYTICS', '/get_screen_events', params, { method: 'GET', headers: {} })
    .then(function (response) {
      dispatch(responseEventData(response));
    }, function (error) {
        dispatch(responseEventData(error));
    });
  }
}



