import React, { Component, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import AppsView from './AppsView'
import session from '../../utils/session';
import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';

const DefaultAside = React.lazy(() => import('./DefaultAside'));
const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

class DefaultLayout extends Component {

  constructor(props) {
    
    super(props)
    this.displayDateOnScreen = ["/audience-overview", "/app-activity", "/active-device", "/active-user", "/screen-analytics", "/install-vs-user"];
    this.state = {
      navigationMenus: null
    }
  }

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  signOut(e) {
    e.preventDefault()
  }

  componentWillMount(){
    let sessionData = session.getSessionData()   
    this.setState({navigationMenus: navigation})
   
  }

  render() {
    let displayAppFilter = this.displayDateOnScreen.indexOf(this.props.location.pathname) !== -1;
    let {navigationMenus} = this.state
    let sessionData = session.getStorageData()
    let loggedInRole = sessionData && sessionData.roles && sessionData.roles.length > 0 ? sessionData.roles[0] : false;
    return (
      <div className="app">
        {loggedInRole &&
        <AppHeader fixed>
          <Suspense  fallback={this.loading()}>
            <DefaultHeader onLogout={e=>this.signOut(e)}/>
          </Suspense>
        </AppHeader>
        }
        <div className="app-body">
          {loggedInRole &&
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
            <AppSidebarNav navConfig={navigationMenus} {...this.props} />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          }
          <main className="main">
            {displayAppFilter && <AppsView {...this.props}/>}
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    if(route.roles.indexOf(loggedInRole) != -1){
                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}

                          render={props => (
                            <route.component {...props} />
                          )} />
                      ) : (null);
                    }

                  })}
                  <Redirect from="/" to={loggedInRole == 'ADMIN' ? '/dashboard' : '/login'} />
                </Switch>
              </Suspense>
            </Container>
          </main>
          <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
