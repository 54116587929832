import { createAction } from 'redux-act';
import httpClient from '../../services/http';

export const requestDownloadSdkData = createAction();
export const responseDownloadSdkData = createAction();


export function downloadSdk(params) {
  return (dispatch) => {
    dispatch(requestDownloadSdkData([]));
    httpClient.call('ANALYTICS', '/download_sdk_request', params, { method: 'GET', headers: {} })
    .then(function (response) {
      dispatch(responseDownloadSdkData(response));
    }, function (error) {
        dispatch(responseDownloadSdkData(error));
    });
  }
}



