import React, { Component } from 'react';
import { connect } from 'react-redux';

class Message extends Component {

  constructor(props) {
      super(props);
      this.state = {
          message: '',
          type: ''
      }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.messageObj && typeof nextProps.messageObj.message !== undefined){
        this.setState(nextProps.messageObj)
    }
  }

  render() {
        let _self = this;
        let {message, type} = _self.state;
        if(typeof message == 'object'){
          message = JSON.stringify(message)
        }

        return(
          <div className={'Toastify__toast-container '+type+' Toastify__toast-container--bottom-right'} style={{zIndex: 1999}}>
            <div className={'Toastify__toast Toastify__toast--'+type}>
              <div role="alert" className="Toastify__toast-body">
                {message && <div className="snackbar show "><div>{message}</div></div>}
              </div>
              {/* <button className={'Toastify__close-button Toastify__close-button--'+type} type="button" aria-label="close">✖</button> */}
              <div className={'Toastify__progress-bar Toastify__progress-bar--'+type} style={{animationDuration: '5000ms', animationPlayState: 'paused', opacity: 1}}></div>
            </div>
          </div>
        )        
    }
}

const mapStateToProps = (state) => {
	return {
    messageObj: state.messageObj
  }
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Message);
