import {createAction} from 'redux-act';
import httpClient from '../../services/http';	



export const requestInstallVsUserData = createAction();
export const responseInstallVsUserData = createAction();
export const requestUninstallVsUserData = createAction();
export const responseUninstallVsUserData = createAction();
export const requestInstallUninstallVsUserData = createAction();
export const responseInstallUninstallVsUserData = createAction();

export function getInstallVsUserData(){
  return (dispatch) => {
      dispatch(requestInstallVsUserData([]));
      httpClient.call('ANALYTICS', '/get_install_vs_user_data', {}, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseInstallVsUserData(response));
      }, function(error){
          dispatch(responseInstallVsUserData(error));
      });
  }
}

export function getUninstallVsUserData(){
  return (dispatch) => {
      dispatch(requestUninstallVsUserData([]));
      httpClient.call('ANALYTICS', '/get_uninstall_vs_user_data', {}, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseUninstallVsUserData(response));
      }, function(error){
          dispatch(responseUninstallVsUserData(error));
      });
  }
}

export function getInstallVsUninstallData(){
  return (dispatch) => {
      dispatch(requestInstallUninstallVsUserData([]));
      httpClient.call('ANALYTICS', '/get_install_vs_uninstall_data', {}, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseInstallUninstallVsUserData(response));
      }, function(error){
          dispatch(responseInstallUninstallVsUserData(error));
      });
  }
}


