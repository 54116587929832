import React from 'react';

const Login = React.lazy(() => import('./views/Pages/Login'));
const Dashboard = React.lazy(() => import('./views/Dashboard'));
const UserList = React.lazy(() => import('./views/UserList'));
const AliveUsers = React.lazy(() => import('./views/AliveUsers'));

const ScreenAnalytics = React.lazy(() => import('./views/ScreenAnalytics'));

const RealTimeOverview = React.lazy(() => import('./views/RealTimeOverview'));
const AudienceOverview = React.lazy(() => import('./views/AudienceOverview'));

const AppActivity = React.lazy(() => import('./views/Usage/AppActivity'));
const ActiveDevice = React.lazy(() => import('./views/Usage/ActiveDevice'));
const ActiveUser = React.lazy(() => import('./views/Usage/ActiveUser'));

const InstallUninstallVsUser = React.lazy(() => import('./views/InstallUninstallVsUser'));
const SdkDownload = React.lazy(() => import('./views/SdkDownload'));
const ScreenView = React.lazy(() => import('./views/ScreenView'));
const Crashes = React.lazy(() => import('./views/Crashes'));
const ScreenEvent = React.lazy(() => import('./views/ScreenEvent'));



const routes = [
  
  // Guest Role
  { path: '/', exact: true, name: 'Home', roles: ['GUEST'] },
  
  // Before login Role
  { path: '/login', exact: true, name: 'Login', component: Login, roles: [false] },


  // After login Role
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, roles: ['ADMIN'] },

  { path: '/user-list', name: 'Dashboard', component: UserList, roles: ['ADMIN']},
    
  { path: '/install-vs-user', name: 'Install Vs User', component: InstallUninstallVsUser, roles: ['ADMIN']},

  { path: '/screen-analytics', name: 'Screen Analytics', component: ScreenAnalytics, roles: ['ADMIN']}, 

  { path: '/realtime-overview', name: 'RealTime Overview', component: RealTimeOverview, roles: ['ADMIN']},
  { path: '/realtime-users', name: 'Online Users', component: AliveUsers, roles: ['ADMIN']},

  { path: '/audience-overview', name: 'Audience Overview', component: AudienceOverview, roles: ['ADMIN']},

  { path: '/app-activity', name: 'App Activity', component: AppActivity, roles: ['ADMIN']},
  { path: '/active-device', name: 'Active Device', component: ActiveDevice, roles: ['ADMIN']},
  { path: '/active-user', name: 'Active User', component: ActiveUser, roles: ['ADMIN']},
  { path: '/sdk-download', name: 'Sdk Download', component: SdkDownload, roles: ['ADMIN']},
  { path: '/screen-view', name: 'Screen View', component: ScreenView, roles: ['ADMIN'] },
  { path: '/crashes-ios', name: 'Crashes', component: Crashes, roles: ['ADMIN'] },
  { path: '/crashes-android', name: 'Crashes', component: Crashes, roles: ['ADMIN'] },
  { path: '/screen-event', name: 'Screen Event', component: ScreenEvent, roles: ['ADMIN'] },

];

export default routes;
