import {createAction} from 'redux-act';
import httpClient from '../../../services/http';

export const requestSpendTimeData = createAction();
export const responseSpendTimeData = createAction();




export function getSpendTime(params){
    return (dispatch) => {
        dispatch(requestSpendTimeData([]));
        httpClient.call('ANALYTICS', '/get_spend_time', params, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responseSpendTimeData(response));
        }, function(error){
            dispatch(responseSpendTimeData(error));
        });
    }
  }

