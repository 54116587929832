import {createAction} from 'redux-act';
import httpClient from '../../../services/http';

export const requestAppSummary = createAction();
export const responseAppSummary = createAction();




export function getAppSummary(params){
  return (dispatch) => {
      dispatch(requestAppSummary([]));
      httpClient.call('ANALYTICS', '/get_app_summary', {summaryType: params.summaryType, startDate: params.startDate, endDate: params.endDate}, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseAppSummary(response));
      }, function(error){
          console.log('error', error);
          dispatch(responseAppSummary(error));
      });
  }
}
