import { createReducer } from 'redux-act';
import { requestScreenData, responseScreenData } from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestScreenData]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseScreenData]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},

}, defaultState);


export default reducer
