import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

class Loader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            processBarPercent: 0
        }
    }

    componentWillReceiveProps(nextProps) {
      let params = {};
      if(typeof nextProps.processBarPercent != 'undefined'){
          params['processBarPercent'] = nextProps.processBarPercent;
      }
      params['visible'] = nextProps.visiblity;
      this.setState(params);
    }


    render() {
      let {visible} = this.state;
      
      if(visible){
        return (
            <div className="loading">
              <div className="loader">
              
                <Spinner  color="primary" style={{ width: '3rem', height: '3rem' }}  />
              </div>
            </div>
          )
      }
      return null;
    }
}

const mapStateToProps = (state) => {
	return {
    visiblity: state.loader.visiblity,
    processBarPercent: state.loader.processBarPercent
  }
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Loader);
