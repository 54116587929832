import {createAction} from 'redux-act';
import httpClient from '../../../services/http';

export const requestSessionDurationData = createAction();
export const responseSessionDurationData = createAction();




export function getSessionDuration() {
    return (dispatch) => {
        dispatch(requestSessionDurationData([]));
		httpClient.call('ANALYTICS', '/get_weekly_session_duration', {}, { method: 'GET', headers: {} }).then(function(response) {
			dispatch(responseSessionDurationData(response));
        }, function(error){
				dispatch(responseSessionDurationData(error));
        });
    }
  }

