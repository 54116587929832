import {createAction} from 'redux-act';
import httpClient from '../../../services/http';

export const requestUserSignup = createAction();
export const responseUserSignup = createAction();




export function getUserSignup(params){
  return (dispatch) => {
      dispatch(requestUserSignup([]));
      httpClient.call('ANALYTICS', '/get_user_signup', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseUserSignup(response));
      }, function(error){
          console.log('error', error);
          dispatch(responseUserSignup(error));
      });
  }
}
