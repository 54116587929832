import { createReducer } from 'redux-act';
import {requestInstallVsUserData, responseInstallVsUserData, requestUninstallVsUserData, responseUninstallVsUserData, requestInstallUninstallVsUserData, responseInstallUninstallVsUserData} from './action';

const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestInstallVsUserData]: (state) => {
		return {
			requestProcess: true,
			installData: [],
		};
	},

	[responseInstallVsUserData]: (state, params) => {
		return {
			requestProcess: false,
			installData: params,
		};
	},

	[requestUninstallVsUserData]: (state) => {
		return {
			requestProcess: true,
			uninstallData: [],
		};
	},

	[responseUninstallVsUserData]: (state, params) => {
		return {
			requestProcess: false,
			uninstallData: params,
		};
	},

	[requestInstallUninstallVsUserData]: (state) => {
		return {
			requestProcess: true,
			installUninstallData: [],
		};
	},

	[responseInstallUninstallVsUserData]: (state, params) => {
		return {
			requestProcess: false,
			installUninstallData: params,
		};
	},


}, defaultState);



export default reducer
