import {createAction} from 'redux-act';
import httpClient from '../../services/http';

export const requestDashboardData = createAction();
export const responseDashboardData = createAction();


export const requestGetSessions = createAction();
export const responseGetSessions = createAction();

export const requestGetTopScreenViews = createAction();
export const responseGetTopScreenViews = createAction();


export const requestGetUserVsReturningUser = createAction();
export const responsGetUserVsReturningUser = createAction();




export function getDashboardData(offset, rowsPerPage){
    return (dispatch) => {
        dispatch(requestDashboardData([]));
        httpClient.call('ANALYTICS', '/get_dashboard_data', {}, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responseDashboardData(response));
        }, function(error){
            dispatch(responseDashboardData(error));
        });
    }
}

export function getTotalSessions(offset, rowsPerPage){
    return (dispatch) => {
        dispatch(requestGetSessions());
        httpClient.call('ANALYTICS', '/usage/get_total_sessions', {}, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responseGetSessions(response));
        }, function(error){
            dispatch(responseGetSessions(error));
        });
    }
}


export function topScreenViews(offset, rowsPerPage){
    return (dispatch) => {
        dispatch(requestGetTopScreenViews());
        httpClient.call('ANALYTICS', '/get_top_recent_views', {}, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responseGetTopScreenViews(response));
        }, function(error){
            dispatch(responseGetTopScreenViews(error));
        });
    }
}


export function getUserVsReturningUser(offset, rowsPerPage){
    return (dispatch) => {
        dispatch(requestGetUserVsReturningUser());
        httpClient.call('ANALYTICS', '/get_user_vs_new_user', {}, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responsGetUserVsReturningUser(response));
        }, function(error){
            dispatch(responsGetUserVsReturningUser(error));
        });
    }
}



export function getAppSummaryData(){
    
    return httpClient.call('ANALYTICS', '/get_app_summary', {}, {method: 'GET', headers: {}})
    
  }

