import { createAction } from 'redux-act';
import httpClient from '../../services/http';

export const requestCrashLogs = createAction();
export const responseCrashLogs = createAction();


export function getCrashLogs(params) {
  return (dispatch) => {
    dispatch(requestCrashLogs([]));
    httpClient.call('ANALYTICS', '/get_crash_logs', params, { method: 'GET', headers: {} })
    .then(function (response) {
      dispatch(responseCrashLogs(response));
    }, function (error) {
        dispatch(responseCrashLogs(error));
    });
  }
}



