import {createAction} from 'redux-act';
import httpClient from '../../../services/http';

export const requestScreenContentReportData = createAction();
export const responseScreenContentReportData = createAction();




export function getScreenContentReport(params){
    return (dispatch) => {
        dispatch(requestScreenContentReportData([]));
        httpClient.call('ANALYTICS', '/get_screen_content_report', params, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responseScreenContentReportData(response));
        }, function(error){
            dispatch(responseScreenContentReportData(error));
        });
    }
  }

