import { createReducer } from 'redux-act';
import {requestUserData, responseUserData, requestUserByDate, responseUserByDate, requestUserHistory, responseUserHistory, requestScreenHistory, responseScreenHistory} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestUserData]: (state) => {
		return {
			requestProcess: true,
			data: [],
		};
	},

	[responseUserData]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},

	[requestUserByDate]: (state) => {
		return {
			requestProcess: true,
			userData: [],
		};
	},

	[responseUserByDate]: (state, params) => {
		return {
			requestProcess: false,
			userData: params,
		};
	},
	[requestUserHistory]: (state) => {
		return {
			requestProcess: true,
			userHistory: [],
		};
	},

	[responseUserHistory]: (state, params) => {
		return {
			requestProcess: false,
			userHistory: params,
		};
	},
	[requestScreenHistory]: (state) => {
		return {
			requestProcess: true,
			screenHistory: [],
		};
	},

	[responseScreenHistory]: (state, params) => {
		return {
			requestProcess: false,
			screenHistory: params,
		};
	},


}, defaultState);



export default reducer
