import { createReducer } from 'redux-act';
import {requestAppSummary, responseAppSummary} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestAppSummary]: (state) => {
    return {
      requestProcess: true,
	  	data: [],
    };
  },

  [responseAppSummary]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},


}, defaultState);



export default reducer
