import { createAction } from "redux-act";
import httpClient from "../../../services/http";
import Session  from '../../../utils/session';
export const requestLoginData = createAction();
export const responseLoginData = createAction();

export function checkLogin(email, password) {
  return dispatch => {
    dispatch(requestLoginData([]));
    httpClient.call('ANALYTICS', '/login', {email, password}, {method: 'POST', headers: {}})
    .then(function(response){
        dispatch(responseLoginData(response));
    }, function(error){
        dispatch(responseLoginData(error));
    });
  };
}
